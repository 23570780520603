
.avatarFileInput {
    position: relative;
    width: 200px;
    min-height: 150px;
    border: 1px dashed #8E8E8E;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--input-bg);
    border-style:dashed;
    border-width: 2px;
    padding: 2em;
    margin: 0;
}
.fileInput input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.fileInputLabel h3 {
    cursor: pointer;
}
.fileInputPreview {
    position: relative;
    display: flex;
}
.fileInputPreview:hover {
    opacity: 0.6;
}
.fileInputPreview .avatar {
    width: 100%;
}
.cancelBtn{
    position: absolute;
    top: 3px;
    right: 4px;
    color:  #000000;
}
.box{
    background-color:#09151c;
    text-align:center;
    padding: 5px;
    margin-top:12px
}
.boxDetails{
    font-size:14px;
    line-height:20px;
    color:#ffffff;
    font-family: 'Montserrat', sans-serif;
}
.card{
    margin-top: 10%;
    display: flex; 
    justify-content: center;
}
.description{
    color:#716F81;
    font-weight: 700;
    font-size: small;
    margin-bottom: 10px;
}
.avatarHeading{
    color:#000;
    margin-top: 20px;
    margin-bottom: 10px;
}