@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
*{
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
  }
  

.auth_avatarFileInput__3_FqB {
    position: relative;
    width: 200px;
    min-height: 150px;
    border: 1px dashed #8E8E8E;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--input-bg);
    border-style:dashed;
    border-width: 2px;
    padding: 2em;
    margin: 0;
}
.auth_fileInput__2HY9J input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.auth_fileInputLabel__EJ2zo h3 {
    cursor: pointer;
}
.auth_fileInputPreview__2nF1h {
    position: relative;
    display: flex;
}
.auth_fileInputPreview__2nF1h:hover {
    opacity: 0.6;
}
.auth_fileInputPreview__2nF1h .auth_avatar__1Makn {
    width: 100%;
}
.auth_cancelBtn__3rkDa{
    position: absolute;
    top: 3px;
    right: 4px;
    color:  #000000;
}
.auth_box__IfGIy{
    background-color:#09151c;
    text-align:center;
    padding: 5px;
    margin-top:12px
}
.auth_boxDetails__2LaVR{
    font-size:14px;
    line-height:20px;
    color:#ffffff;
    font-family: 'Montserrat', sans-serif;
}
.auth_card__26kp2{
    margin-top: 10%;
    display: flex; 
    justify-content: center;
}
.auth_description__9VDUz{
    color:#716F81;
    font-weight: 700;
    font-size: small;
    margin-bottom: 10px;
}
.auth_avatarHeading__IrWVa{
    color:#000;
    margin-top: 20px;
    margin-bottom: 10px;
}
